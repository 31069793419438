var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "endscreen-feedback" },
    [
      !_vm.isLoading
        ? _c(
            "div",
            [
              _vm.isNewEndScreenFeedbackView
                ? _c(
                    "vs-table",
                    {
                      key: _vm.tableKey,
                      ref: "table",
                      attrs: {
                        pagination: "",
                        "max-items": _vm.itemsPerPage,
                        data: _vm.filteredEndScreenFeedbackData,
                        noDataText: _vm.$t("views.home.noDataAvailable"),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var data = ref.data
                              return [
                                _c(
                                  "tbody",
                                  _vm._l(data, function (tr, indextr) {
                                    return _c(
                                      "vs-tr",
                                      { key: indextr, attrs: { data: tr } },
                                      [
                                        _c(
                                          "vs-td",
                                          {
                                            staticClass:
                                              "vega-scores-analytics__list-view--column",
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  tr.updated
                                                    ? _vm.formatDate(
                                                        tr.updated.value
                                                      )
                                                    : ""
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            staticClass:
                                              "vega-scores-analytics__list-view--column",
                                          },
                                          [
                                            tr.question_first
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "endscreen-feedback-sidebar__content--item",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "endscreen-feedback-sidebar__content--question",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              tr.question_first
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("rating-stars", {
                                                      attrs: {
                                                        grade: tr.grade_first,
                                                        maxStars: 5,
                                                        disabled: true,
                                                        color: "#3B86F7",
                                                        size: "18",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            tr.question_second
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-3 endscreen-feedback-sidebar__content--item",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "endscreen-feedback-sidebar__content--question",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              tr.question_second
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("rating-stars", {
                                                      attrs: {
                                                        grade: tr.grade_second,
                                                        maxStars: 5,
                                                        disabled: true,
                                                        color: "#3B86F7",
                                                        size: "18",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            tr.question_third
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-3 endscreen-feedback-sidebar__content--item",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "endscreen-feedback-sidebar__content--question",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            tr.question_third
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("rating-stars", {
                                                      attrs: {
                                                        grade: tr.grade_third,
                                                        maxStars: 5,
                                                        disabled: true,
                                                        color: "#3B86F7",
                                                        size: "18",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            staticClass:
                                              "vega-scores-analytics__list-view--column",
                                          },
                                          [
                                            tr.feedback_message
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "endscreen-feedback-sidebar__content--message",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          tr.feedback_message
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4101737892
                      ),
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column items-start flex-grow justify-center",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("vs-th", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "endscreenFeedback.customerFeedbackDetails"
                                  )
                                ) +
                                "\n        "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", { attrs: { "sort-key": "date" } }, [
                            _vm._v(_vm._s(_vm.$t("endscreenFeedback.date"))),
                          ]),
                          _c("vs-th", { attrs: { "sort-key": "questions" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("endscreenFeedback.customerFeedback")
                              )
                            ),
                          ]),
                          _c("vs-th", { attrs: { "sort-key": "message" } }, [
                            _vm._v(_vm._s(_vm.$t("endscreenFeedback.message"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _c(
                    "vs-collapse",
                    { staticClass: "endscreen-feedback__collapse" },
                    [
                      _c("vs-collapse-item", { attrs: { open: "" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("vs-th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("endscreenFeedback.customerFeedback")
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm.newestNotClosedFeedback
                          ? _c(
                              "div",
                              { staticClass: "endscreen-feedback__general" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "endscreen-feedback__general--left",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "endscreen-feedback__general--title",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "endscreenFeedback.allQuestions"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm.newestNotClosedFeedback.grade_average
                                      ? _c("rating-stars", {
                                          attrs: {
                                            grade:
                                              _vm.newestNotClosedFeedback
                                                .grade_average,
                                            maxStars: 5,
                                            disabled: true,
                                            color: "#3B86F7",
                                            size: "18",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.newestNotClosedFeedback
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "endscreen-feedback__current-questions",
                              },
                              [
                                _vm.newestNotClosedFeedback.grade_first
                                  ? _c("end-screen-feedback-question", {
                                      attrs: {
                                        title:
                                          _vm.newestNotClosedFeedback
                                            .question_first,
                                        value:
                                          _vm.newestNotClosedFeedback
                                            .grade_first,
                                      },
                                    })
                                  : _vm._e(),
                                _vm.newestNotClosedFeedback.grade_second
                                  ? _c("end-screen-feedback-question", {
                                      attrs: {
                                        title:
                                          _vm.newestNotClosedFeedback
                                            .question_second,
                                        value:
                                          _vm.newestNotClosedFeedback
                                            .grade_second,
                                      },
                                    })
                                  : _vm._e(),
                                _vm.newestNotClosedFeedback.grade_third
                                  ? _c("end-screen-feedback-question", {
                                      attrs: {
                                        title:
                                          _vm.newestNotClosedFeedback
                                            .question_third,
                                        value:
                                          _vm.newestNotClosedFeedback
                                            .grade_third,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "endscreen-feedback__stats" },
                          [
                            _c("general-info-item", {
                              attrs: {
                                title: _vm.$t(
                                  "endscreenFeedback.sentFeedbackViaMessage"
                                ),
                                value: _vm.sentFeedbackViaMessageCount,
                                isLoading: _vm.isLoading,
                              },
                            }),
                            _c("general-info-item", {
                              attrs: {
                                title: _vm.$t(
                                  "endscreenFeedback.closedFeedbackForm"
                                ),
                                value: _vm.closedFeedbackFormCount,
                                isLoading: _vm.isLoading,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          )
        : _c("placeholder-text"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }