var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    [
      !_vm.isAgentsLoading
        ? _c(
            "div",
            { staticClass: "agents-data" },
            [
              _c("vs-list-header", {
                staticClass: "card-title-font-size",
                attrs: { title: _vm.$t("vue.agents"), color: "primary" },
              }),
              _c(
                "scroll-view",
                { staticClass: "agents-data-scroll-area" },
                _vm._l(_vm.agents, function (agent, index) {
                  return _c(
                    "vs-list-item",
                    {
                      key: index,
                      staticClass: "list-item-wording",
                      attrs: { title: agent.name },
                    },
                    [
                      _c("vs-chip", { attrs: { color: "#275D73" } }, [
                        _vm._v(_vm._s(agent.sessions)),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c("placeholder-text"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }