var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    [
      !_vm.isVisitorsLocationLoading
        ? _c(
            "div",
            { staticClass: "visitors-location-data" },
            [
              _c("div", { staticClass: "flex flex-row items-center" }, [
                _c(
                  "div",
                  { staticClass: "relative w-full" },
                  [
                    _c("vs-list-header", {
                      staticClass: "card-title-font-size",
                      attrs: {
                        title: _vm.$t("vue.visitorLocations"),
                        color: "primary",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ml-2 vs-list--header" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticStyle: { width: "17px" },
                        attrs: {
                          text: _vm.$t(
                            "info.dashboard.valueMeasuredByRequests"
                          ),
                          position: "top",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "17",
                            height: "17",
                            src: _vm.infoImg,
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "scroll-view",
                { staticClass: "visitors-location-data-scroll-area" },
                _vm._l(_vm.topVisitors, function (city, index) {
                  return _c(
                    "vs-list-item",
                    {
                      key: index,
                      staticClass: "list-item-wording",
                      attrs: { title: city.city },
                    },
                    [
                      _c("vs-chip", { attrs: { color: "#275D73" } }, [
                        _vm._v(_vm._s(city.visits)),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c("placeholder-text"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }