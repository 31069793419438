var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "general-info-item" },
    [
      !_vm.isLoading
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "flex flex-row justify-center items-center",
                staticStyle: { height: "20px" },
              },
              [
                _c("div", {
                  staticClass: "general-info-item-title",
                  domProps: { textContent: _vm._s(_vm.$t(_vm.title)) },
                }),
                _vm.info
                  ? _c(
                      "div",
                      { staticClass: "ml-4" },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            staticStyle: { height: "17px" },
                            attrs: { text: _vm.$t(_vm.info), position: "top" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                width: "17",
                                height: "17",
                                src: _vm.infoImg,
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c("div", {
              staticClass: "general-info-item-value",
              domProps: { textContent: _vm._s(_vm.value) },
            }),
          ])
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }