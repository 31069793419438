var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "content-loader",
    {
      attrs: {
        width: 340,
        height: 84,
        speed: 3,
        primaryColor: "#b4ccf3",
        secondaryColor: "#ffffff",
      },
    },
    [
      _c("rect", {
        attrs: { x: "0", y: "0", rx: "3", ry: "3", width: "67", height: "11" },
      }),
      _c("rect", {
        attrs: {
          x: "76",
          y: "0",
          rx: "3",
          ry: "3",
          width: "140",
          height: "11",
        },
      }),
      _c("rect", {
        attrs: {
          x: "127",
          y: "48",
          rx: "3",
          ry: "3",
          width: "53",
          height: "11",
        },
      }),
      _c("rect", {
        attrs: {
          x: "187",
          y: "48",
          rx: "3",
          ry: "3",
          width: "72",
          height: "11",
        },
      }),
      _c("rect", {
        attrs: {
          x: "18",
          y: "48",
          rx: "3",
          ry: "3",
          width: "100",
          height: "11",
        },
      }),
      _c("rect", {
        attrs: { x: "0", y: "71", rx: "3", ry: "3", width: "37", height: "11" },
      }),
      _c("rect", {
        attrs: {
          x: "18",
          y: "23",
          rx: "3",
          ry: "3",
          width: "140",
          height: "11",
        },
      }),
      _c("rect", {
        attrs: {
          x: "166",
          y: "23",
          rx: "3",
          ry: "3",
          width: "173",
          height: "11",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }