<template>
  <div class="endscreen-feedback-question-item">
    <div class="flex flex-row justify-center items-center">
      <div class="endscreen-feedback-question-item-title">
        {{ title }}
      </div>
      <div class="ml-4" v-if="info">
        <vx-tooltip style="height: 17px" :text="$t(info)" position="top">
          <img width="17" height="17" :src="infoImg" />
        </vx-tooltip>
      </div>
    </div>
    <rating-stars :grade="value" :maxStars="5" :disabled="true" color="#3B86F7" size="18" />
  </div>
</template>

<script>
import RatingStars from '@/components/rating-stars/RatingStars.vue'

export default {
  name: 'EndScreenFeedbackQuestion',
  components: {
    RatingStars
  },
  props: {
    title: {
      type: String,
      default: 'Item Title'
    },
    value: {
      default: '0'
    },
    info: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg')
    }
  }
}
</script>

<style lang="scss">
.endscreen-feedback-question {
  &-item {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 25px 0px rgba($color: #000000, $alpha: 0.1);
    padding: 20px 30px;
    text-align: center;
    min-height: 115px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    &-title {
      color: #626262;
      font-size: 15px;
      margin-bottom: 15px;
    }

    &-value {
      color: #000;
      font-size: 2.5rem;
    }
  }
}
</style>
