var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-list" },
    [
      !_vm.isLoading
        ? _c(
            "div",
            [
              _c(
                "vs-collapse",
                { attrs: { "open-hover": "" } },
                [
                  _c(
                    "vs-collapse-item",
                    [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _c("vs-th", [
                            _vm._v(_vm._s(_vm.$t(_vm.tableConfig.cl1_name))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "vs-table",
                        {
                          attrs: {
                            data: _vm.tableData,
                            noDataText: _vm.$t("views.home.noDataAvailable"),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var data = ref.data
                                  return [
                                    _c(
                                      "tbody",
                                      _vm._l(data, function (element, i) {
                                        return _c(
                                          "vs-tr",
                                          { key: i },
                                          [
                                            _c("vs-td", [
                                              _vm._v(_vm._s(element.name)),
                                            ]),
                                            _c("vs-td", [
                                              _vm._v(_vm._s(element.value)),
                                            ]),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2088625757
                          ),
                        },
                        [
                          _c(
                            "template",
                            {
                              staticClass: "flex justify-around",
                              slot: "thead",
                            },
                            [
                              _c("vs-th"),
                              _vm.tableConfig.cl2_name
                                ? _c("vs-th", [
                                    _vm._v(
                                      _vm._s(_vm.$t(_vm.tableConfig.cl2_name))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("placeholder-text"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }