<template>
  <div class="table-list">
    <div v-if="!isLoading">
      <vs-collapse open-hover>
        <vs-collapse-item>
          <div slot="header">
            <vs-th>{{ $t(tableConfig.cl1_name) }}</vs-th>
          </div>
          <vs-table :data="tableData" :noDataText="$t('views.home.noDataAvailable')">
            <template slot="thead" class="flex justify-around">
              <vs-th></vs-th>
              <vs-th v-if="tableConfig.cl2_name">{{ $t(tableConfig.cl2_name) }}</vs-th>
            </template>
            <template slot-scope="{ data }">
              <tbody>
                <vs-tr v-for="(element, i) in data" :key="i">
                  <vs-td>{{ element.name }}</vs-td>
                  <vs-td>{{ element.value }}</vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </vs-collapse-item>
      </vs-collapse>
    </div>
    <placeholder-text v-else />
  </div>
</template>

<script>
import PlaceholderText from '@/components/placeholder-text/PlaceholderText.vue'

export default {
  name: 'TableView',
  components: {
    PlaceholderText
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    tableConfig: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean
    },
    measuredBy: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  computed: {
    SHOW_MEASURED_BY_REQUESTS() {
      return this.measuredBy === 'requests'
    }
  }
}
</script>

<style lang="scss">
.table-list {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba($color: #000000, $alpha: 0.1);
  padding: 20px 20px;

  .vs-collapse .open-item {
    background: transparent !important;
  }

  .vs-collapse {
    padding: 0 !important;
    .vs-collapse-item--header {
      padding: 0 !important;
    }

    .vs-collapse-item {
      border-bottom: none !important;
      border-radius: 6px;
      margin-bottom: 0px !important;
      background: transparent !important;
    }
    .open-item {
      background: transparent !important;
    }
  }

  .vs-con-table {
    .vs-table--thead {
      th {
        .vs-table-text {
          font-size: 14px !important;
          font-weight: 700 !important;
          box-shadow: none !important;
        }
      }
    }
  }

  .vs-con-tbody {
    border: none;
    background: #fff;
    overflow-x: hidden;
    .vs-table--tbody-table {
      .vs-table--thead {
        th {
          padding: 0px;
        }
      }
    }
    .tr-values {
      border-bottom: none;

      &:hover {
        transform: none !important;
      }
    }
  }
}
</style>
