<template>
  <div class="general-info-item">
    <div v-if="!isLoading">
      <div class="flex flex-row justify-center items-center" style="height: 20px">
        <div class="general-info-item-title" v-text="$t(title)" />
        <div class="ml-4" v-if="info">
          <vx-tooltip style="height: 17px" :text="$t(info)" position="top">
            <img width="17" height="17" :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>
      <div class="general-info-item-value" v-text="value"></div>
    </div>
    <placeholder-text-small v-else />
  </div>
</template>

<script>
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  name: 'GeneralInfoItem',
  components: {
    PlaceholderTextSmall
  },
  props: {
    title: {
      type: String,
      default: 'Item Title'
    },
    value: {
      default: '0'
    },
    isLoading: {
      type: Boolean
    },
    info: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg')
    }
  }
}
</script>

<style lang="scss">
.general-info {
  &-item {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 25px 0px rgba($color: #000000, $alpha: 0.1);
    padding: 20px 30px;
    width: 100%;
    text-align: center;
    height: 115px;

    &-title {
      color: #626262;
      font-size: 15px;
    }

    &-value {
      color: #000;
      font-size: 2.5rem;
    }
  }
}
</style>
