<template>
  <vx-card>
    <div class="visitors-location-data" v-if="!isVisitorsLocationLoading">
      <div class="flex flex-row items-center">
        <div class="relative w-full">
          <vs-list-header :title="$t('vue.visitorLocations')" class="card-title-font-size" color="primary"></vs-list-header>
        </div>
        <div class="ml-2 vs-list--header">
          <vx-tooltip style="width: 17px" :text="$t('info.dashboard.valueMeasuredByRequests')" position="top">
            <img width="17" height="17" :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>

      <scroll-view class="visitors-location-data-scroll-area">
        <vs-list-item v-for="(city, index) in topVisitors" :key="index" :title="city.city" class="list-item-wording">
          <vs-chip color="#275D73">{{ city.visits }}</vs-chip>
        </vs-list-item>
      </scroll-view>
    </div>
    <placeholder-text v-else />
  </vx-card>
</template>

<script>
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import PlaceholderText from '@/components/placeholder-text/PlaceholderText.vue'

export default {
  name: 'VisitorLocations',
  components: {
    ScrollView,
    PlaceholderText
  },
  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  props: {
    topVisitors: {
      type: Array,
      required: true
    },
    isVisitorsLocationLoading: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
.card-title-font-size {
  font-size: 1.3em;
}

.visitors-location-data {
  &-scroll-area {
    height: 235px;

    .scroll-content {
      margin-right: 20px;
    }

    .scrollbar-thumb {
      width: 4px !important;
      background-color: rgb(18, 89, 141);
    }

    .scrollbar-track-y {
      width: 4px !important;
      background: rgba(230, 236, 241, 0.5);
    }
  }

  .vs-list--title {
    font-weight: 900;
    font-size: 14px;
  }

  .list-item-wording {
    .vs-list--title {
      font-weight: 400;
    }
  }
}
</style>
