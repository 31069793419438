var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "endscreen-feedback-question-item" },
    [
      _c("div", { staticClass: "flex flex-row justify-center items-center" }, [
        _c("div", { staticClass: "endscreen-feedback-question-item-title" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
        ]),
        _vm.info
          ? _c(
              "div",
              { staticClass: "ml-4" },
              [
                _c(
                  "vx-tooltip",
                  {
                    staticStyle: { height: "17px" },
                    attrs: { text: _vm.$t(_vm.info), position: "top" },
                  },
                  [
                    _c("img", {
                      attrs: { width: "17", height: "17", src: _vm.infoImg },
                    }),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("rating-stars", {
        attrs: {
          grade: _vm.value,
          maxStars: 5,
          disabled: true,
          color: "#3B86F7",
          size: "18",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }