var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    [
      !_vm.isRouteClicksLoading
        ? _c(
            "div",
            { staticClass: "route-clicks" },
            [
              _c("vs-list-header", {
                staticClass: "card-title-font-size",
                attrs: {
                  title: _vm.$t("vue.routeScreenClicks"),
                  color: "primary",
                },
              }),
              _c(
                "scroll-view",
                { staticClass: "route-clicks-scroll-area" },
                _vm._l(_vm.routeClicks, function (item, index) {
                  return _c(
                    "vs-list-item",
                    {
                      key: index,
                      staticClass: "list-item-wording",
                      attrs: { title: item.name },
                    },
                    [
                      _c("vs-chip", { attrs: { color: "#275D73" } }, [
                        _vm._v(_vm._s(item.clicks)),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c("placeholder-text"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }