var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-name w-full relative" },
    [
      !_vm.isBrowserDataLoading
        ? _c("vx-card", [
            _c("div", { staticClass: "flex flex-row items-center" }, [
              _c(
                "div",
                { staticClass: "relative w-full" },
                [
                  _c("vs-list-header", {
                    staticClass: "card-title-font-size",
                    attrs: { title: _vm.$t(_vm.title), color: "primary" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-2 vs-list--header" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      staticStyle: { width: "17px" },
                      attrs: {
                        text: _vm.$t("info.dashboard.valueMeasuredByRequests"),
                        position: "top",
                      },
                    },
                    [
                      _c("img", {
                        attrs: { width: "17", height: "17", src: _vm.infoImg },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              [
                _c("vue-apex-charts", {
                  key: _vm.chartKey,
                  attrs: {
                    height: "180",
                    type: "donut",
                    options: _vm.chartOptions,
                    series: _vm.series,
                  },
                }),
              ],
              1
            ),
          ])
        : _c("placeholder-text"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }