var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info w-full relative" },
    [
      !_vm.isLoading
        ? _c("vx-card", { staticClass: "h-full" }, [
            _c("div", { staticClass: "flex flex-row items-start" }, [
              _c(
                "div",
                { staticClass: "relative w-full" },
                [
                  _c("vs-list-header", {
                    staticClass: "mb-0 pb-2 card-title-font-size",
                    attrs: {
                      title: _vm.$t("views.home.agentConversationRating"),
                      color: "primary",
                    },
                  }),
                  _c("div", { staticClass: "chart-info__subtitle" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("views.home.agentConversationRatingSubtitle")
                      )
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-2 vs-list--header" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      staticStyle: { width: "17px" },
                      attrs: {
                        text: _vm.$t(
                          "info.dashboard.agentConversationRatingInfo"
                        ),
                        position: "top",
                      },
                    },
                    [
                      _c("img", {
                        attrs: { width: "17", height: "17", src: _vm.infoImg },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mt-5 agent-converting-main-value" }, [
              _vm._v(_vm._s(_vm.totalAmount)),
            ]),
            _c("div", { staticClass: "mb-5 agent-converting-main-title" }, [
              _vm._v(_vm._s(_vm.$t("views.home.totalRatedConversations"))),
            ]),
            _vm.visitorStatusOverviewData
              ? _c(
                  "div",
                  { staticClass: "visitor-status-list" },
                  _vm._l(
                    Object.keys(_vm.visitorStatusOverviewData),
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: _vm.visitorStatusOverviewData[item].type,
                          staticClass: "visitor-status-list__item",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "visitor-status-list__item__info" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "visitor-status-list__item__info__title",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm
                                            .demoVisitorStatusOverviewDataLabels[
                                            index
                                          ].name
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "visitor-status-list__item__amount",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.visitorStatusOverviewData[item].amount
                                )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "visitor-status-list__item__percent",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.visitorStatusOverviewData[item].percent
                                ) + "%"
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ])
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }