<template>
  <div class="endscreen-feedback">
    <div v-if="!isLoading">
      <vs-table v-if="isNewEndScreenFeedbackView" :key="tableKey" ref="table" v-model="selected" pagination :max-items="itemsPerPage" :data="filteredEndScreenFeedbackData" :noDataText="$t('views.home.noDataAvailable')">
        <div slot="header" class="flex flex-column items-start flex-grow justify-center">
          <vs-th>
            {{ $t('endscreenFeedback.customerFeedbackDetails') }}
          </vs-th>
        </div>

        <template slot="thead">
          <vs-th sort-key="date">{{ $t('endscreenFeedback.date') }}</vs-th>
          <vs-th sort-key="questions">{{ $t('endscreenFeedback.customerFeedback') }}</vs-th>
          <vs-th sort-key="message">{{ $t('endscreenFeedback.message') }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="vega-scores-analytics__list-view--column">
                {{ tr.updated ? formatDate(tr.updated.value) : '' }}
              </vs-td>

              <vs-td class="vega-scores-analytics__list-view--column">
                <div class="endscreen-feedback-sidebar__content--item" v-if="tr.question_first">
                  <span class="endscreen-feedback-sidebar__content--question"> {{ tr.question_first }}</span>
                  <rating-stars :grade="tr.grade_first" :maxStars="5" :disabled="true" color="#3B86F7" size="18" />
                </div>
                <div class="mt-3 endscreen-feedback-sidebar__content--item" v-if="tr.question_second">
                  <span class="endscreen-feedback-sidebar__content--question"> {{ tr.question_second }}</span>
                  <rating-stars :grade="tr.grade_second" :maxStars="5" :disabled="true" color="#3B86F7" size="18" />
                </div>
                <div class="mt-3 endscreen-feedback-sidebar__content--item" v-if="tr.question_third">
                  <span class="endscreen-feedback-sidebar__content--question">{{ tr.question_third }}</span>
                  <rating-stars :grade="tr.grade_third" :maxStars="5" :disabled="true" color="#3B86F7" size="18" />
                </div>
              </vs-td>

              <vs-td class="vega-scores-analytics__list-view--column">
                <div class="endscreen-feedback-sidebar__content--message" v-if="tr.feedback_message">
                  {{ tr.feedback_message }}
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>

      <vs-collapse v-else class="endscreen-feedback__collapse">
        <vs-collapse-item open>
          <div slot="header">
            <vs-th>{{ $t('endscreenFeedback.customerFeedback') }}</vs-th>
          </div>
          <div class="endscreen-feedback__general" v-if="newestNotClosedFeedback">
            <div class="endscreen-feedback__general--left">
              <div class="endscreen-feedback__general--title">{{ $t('endscreenFeedback.allQuestions') }}</div>
              <rating-stars
                :grade="newestNotClosedFeedback.grade_average"
                :maxStars="5"
                :disabled="true"
                color="#3B86F7"
                size="18"
                v-if="newestNotClosedFeedback.grade_average"
              />
            </div>
          </div>
          <div class="endscreen-feedback__current-questions" v-if="newestNotClosedFeedback">
            <end-screen-feedback-question
              v-if="newestNotClosedFeedback.grade_first"
              :title="newestNotClosedFeedback.question_first"
              :value="newestNotClosedFeedback.grade_first"
            />
            <end-screen-feedback-question
              v-if="newestNotClosedFeedback.grade_second"
              :title="newestNotClosedFeedback.question_second"
              :value="newestNotClosedFeedback.grade_second"
            />
            <end-screen-feedback-question
              v-if="newestNotClosedFeedback.grade_third"
              :title="newestNotClosedFeedback.question_third"
              :value="newestNotClosedFeedback.grade_third"
            />
          </div>
          <div class="endscreen-feedback__stats">
            <general-info-item :title="$t('endscreenFeedback.sentFeedbackViaMessage')" :value="sentFeedbackViaMessageCount" :isLoading="isLoading" />
            <general-info-item :title="$t('endscreenFeedback.closedFeedbackForm')" :value="closedFeedbackFormCount" :isLoading="isLoading" />
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </div>

    <placeholder-text v-else />
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(localizedFormat)

import RatingStars from '@/components/rating-stars/RatingStars.vue'
import EndScreenFeedbackQuestion from './EndScreenFeedbackQuestion.vue'
import PlaceholderText from '@/components/placeholder-text/PlaceholderText.vue'
import GeneralInfoItem from './GeneralInfoItem.vue'

export default {
  name: 'EndScreenFeedback',
  components: {
    RatingStars,
    EndScreenFeedbackQuestion,
    PlaceholderText,
    GeneralInfoItem
  },
  props: {
    value: {
      default: '0'
    },
    isLoading: {
      type: Boolean
    },
    isNewEndScreenFeedbackView: {
      type: Boolean,
    }
  },
  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg'),
      tableKey: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      selected: [],
      itemsPerPage: 5,
    }
  },
  computed: {
    filteredEndScreenFeedbackData() {
      if (this.isNewEndScreenFeedbackView && this.value && this.value.length) {
        const filteredData = this.value.filter((el) => el.feedback_type !== 'closed' && el.agent_id)
        return filteredData
      }
      return []
    },
    newestNotClosedFeedback() {
      if (!this.isNewEndScreenFeedbackView && this.value && this.value.length) {
        const notClosedFeedbacks = this.value.filter((el) => el.feedback_type !== 'closed')
        if (notClosedFeedbacks && notClosedFeedbacks.length) {
          return notClosedFeedbacks[0]
        }
        return null
      }
      return null
    },
    sentFeedbackViaMessageCount() {
      if (!this.isNewEndScreenFeedbackView && this.value && this.value.length) {
        const filteredFeedback = this.value.filter((el) => el.feedback_type === 'message')
        return filteredFeedback && filteredFeedback.length
      }
      return 0
    },
    closedFeedbackFormCount() {
      if (!this.isNewEndScreenFeedbackView && this.value && this.value.length) {
        const filteredFeedback = this.value.filter((el) => el.feedback_type === 'closed')
        return filteredFeedback && filteredFeedback.length
      }
      return 0
    }
  },
  methods: {
    formatDate(timestamp) {
      return dayjs(timestamp).format('LLL')
    }
  },
}
</script>

<style lang="scss" scoped>
.endscreen-feedback {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba($color: #000000, $alpha: 0.1);
  padding: 20px 20px;
  &__general {
    min-height: 45px;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e6ecf1;
    border-radius: 6px;
    box-shadow: 0px 4px 25px 0px rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 10px;
    &--left {
      display: flex;
      align-items: center;
      @media (max-width: 425px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &--title {
      margin-right: 30px;
      font-size: 14px;
      font-weight: 500;
      color: #626262;
    }
    &--arrow {
      display: flex;
    }
  }
  &__current-questions {
    display: grid;
    grid-template-columns: repeat(3, minmax(190px, auto));
    grid-column-gap: 15px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
    }
  }
  &__stats {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .general-info-item {
      width: calc((100% / 2) - 7.5px);
    }
    @media (max-width: 767px) {
      flex-direction: column;
      margin-top: 0;
      .general-info-item {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
</style>

<style lang="scss">
.endscreen-feedback__collapse.vs-collapse {
  padding: 0 !important;
  &.open-item {
    background: transparent !important;
  }

  .vs-collapse-item--header {
    padding: 0 !important;
  }
  .vs-collapse-item {
    border-bottom: none !important;
    border-radius: 6px;
    margin-bottom: 0px !important;
    background: transparent !important;
  }
  .open-item {
    background: transparent !important;
  }
}
</style>
