<template>
  <vx-card>
    <div class="agents-data" v-if="!isAgentsLoading">
      <vs-list-header :title="$t('vue.agents')" class="card-title-font-size" color="primary" />
      <scroll-view class="agents-data-scroll-area">
        <vs-list-item v-for="(agent, index) in agents" :key="index" :title="agent.name" class="list-item-wording">
          <vs-chip color="#275D73">{{ agent.sessions }}</vs-chip>
        </vs-list-item>
      </scroll-view>
    </div>
    <placeholder-text v-else />
  </vx-card>
</template>

<script>
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import PlaceholderText from '@/components/placeholder-text/PlaceholderText.vue'
export default {
  name: 'Agents',
  components: {
    ScrollView,
    PlaceholderText
  },
  props: {
    agents: {
      type: Array,
      required: true
    },
    isAgentsLoading: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
.card-title-font-size {
  font-size: 1.3em;
}

.agents-data {
  &-scroll-area {
    height: 235px;

    .scroll-content {
      margin-right: 20px;
    }

    .scrollbar-thumb {
      width: 4px !important;
      background-color: rgb(18, 89, 141);
    }

    .scrollbar-track-y {
      width: 4px !important;
      background: rgba(230, 236, 241, 0.5);
    }
  }
  .vs-list--title {
    font-weight: 900;
    font-size: 14px;
  }

  .list-item-wording {
    .vs-list--title {
      font-weight: 400;
    }
  }
}
</style>
