<template>
  <div class="device-name w-full relative">
    <vx-card v-if="!isBrowserDataLoading">
      <div class="flex flex-row items-center">
        <div class="relative w-full">
          <vs-list-header :title="$t(title)" class="card-title-font-size" color="primary"></vs-list-header>
        </div>
        <div class="ml-2 vs-list--header">
          <vx-tooltip style="width: 17px" :text="$t('info.dashboard.valueMeasuredByRequests')" position="top">
            <img width="17" height="17" :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>

      <div>
        <vue-apex-charts height="180" type="donut" :key="chartKey" :options="chartOptions" :series="series" />
      </div>
    </vx-card>

    <placeholder-text v-else />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import PlaceholderText from '@/components/placeholder-text/PlaceholderText.vue'

export default {
  name: 'DeviceName',
  components: {
    VueApexCharts,
    PlaceholderText
  },
  props: {
    title: {
      type: String
    },
    deviceData: {
      type: Array,
      required: true
    },
    isBrowserDataLoading: {
      type: Boolean
    }
  },
  data: () => ({
    infoImg: require('@/assets/images/elements/info.svg'),
    deviceList: [],
    series: [],
    chartKey: Math.random().toString(36).substring(2, 15),
    chartOptions: {
      legend: { show: true, floating: false, position: 'right' },

      chart: {
        height: 180,
        type: 'donut',
        toolbar: {
          show: false
        },
        fontFamily: 'Lato, sans-serif'
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false
            }
          }
        }
      },
      stroke: { width: 0 }
    }
  }),
  watch: {
    deviceData(value) {
      this.deviceList = value
      this.updateChart()
    }
  },
  created() {
    this.deviceList = this.deviceData
    this.updateChart()
  },
  methods: {
    updateChart() {
      this.series = []

      this.chartOptions.labels = this.deviceList.map((x) => x.name)

      for (let i = 0; i < this.deviceList.length; i++) {
        const device = this.deviceList[i]
        this.series[i] = device.value
      }
      this.chartKey = Math.random().toString(36).substring(2, 15)
    }
  }
}
</script>

<style lang="scss">
.device-name {
  .vx-card {
    height: 100%;
  }
}
</style>
